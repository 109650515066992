<template>
  <BCard body>
    <div class="d-flex justify-between">
      <div class="d-flex items-center">
        <b-button
          variant="primary"
          size="sm"
          class="mr-1 rounded-lg p-0"
          @click="$router.go(-1)"
        >
          <feather-icon
            size="2x"
            icon="ChevronLeftIcon"
          />
        </b-button>
        <h3 class="font-bold text-black mb-0">
          Detail Packing
        </h3>
      </div>
      <BButton
        variant="primary"
        @click="goToDetail(detail.id)"
      >
        Label Pengiriman
      </BButton>
    </div>
    <div class="mt-2 border rounded-[16px] p-2">
      <h4 class="font-bold text-black d-inline-flex mb-0">
        Penjemputan
      </h4>
      <div class="mt-1 space-y-3">
        <b-row>
          <b-col cols="2">
            <div class="font-bold d-flex justify-between">
              Alamat
              <span>:</span>
            </div>
          </b-col>
          <b-col>
            <div class="text-black font-bold">
              {{ detail.name }}
            </div>
            <div class="">
              {{ detail.address }}
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <div class="font-bold d-flex justify-between">
              Tanggal
              <span>:</span>
            </div>
          </b-col>
          <b-col>
            {{ formatDate(detail.date) }}
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col cols="2">
            <div class="font-bold d-flex justify-between">
              Waktu Jemput
              <span>:</span>
            </div>
          </b-col>
          <b-col>
            {{ detail.time }}
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col cols="2">
            <div class="font-bold d-flex justify-between">
              Kendaraan
              <span>:</span>
            </div>
          </b-col>
          <b-col class="d-flex space-x-2">
            <img
              :src="handleVehicle(detail.vehicle)"
              alt="vehicle"
            >
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="mt-2 border rounded-[16px]">
      <div class="d-flex justify-between align-items-center p-2">
        <div class="text-black font-bold">
          Packing List
        </div>
        <b-popover
          target="btnDownload"
          variant="primary"
          triggers="hover"
          placement="top"
          boundary="viewport"
        >
          <div class="text-sm text-[#828282]">
            Download & Share Packing List
          </div>
        </b-popover>
        <b-button
          id="btnDownload"
          variant="primary"
          class="d-flex items-center space-x-2"
          @click="downloadPackingList()"
        >
          <div class="">
            Download
          </div>
          <img
            class="h-5"
            src="https://storage.googleapis.com/komerce/assets/svg/telegram-alt.svg"
            alt=""
          >
        </b-button>
      </div>
      <BOverlay
        :show="loading"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <BTable
          :items="products"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
        >
          <template #cell(produk)="data">
            <div class="d-flex items-start space-x-2">
              <b-img
                :src="data.item.images_path ? data.item.images_path : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                fluid
                class="image-product"
              />
              <div class="">
                <div class="text-black font-bold">
                  {{ data.item.product_name }}
                </div>
                <ul v-if="data.item.is_variant === 1">
                  <li
                    v-for="(item, index) in data.item.variant"
                    :key="index"
                  >
                    {{ item.variant_name }}
                  </li>
                </ul>
                <div
                  v-else
                  class=""
                >
                  -
                </div>
              </div>
            </div>
          </template>
          <template #cell(jumlah)="data">
            <div class="invisible">
              {{ data.item.product_name }}
            </div>
            <ul
              v-if="data.item.is_variant === 1"
            >
              <li
                v-for="(item, index) in data.item.variant"
                :key="index"
              >
                {{ 'X ' + handleTotalProduct(item.total) }}
              </li>
            </ul>
            <div
              v-else
              class=""
            >
              {{ 'X ' + handleTotalProduct(data.item.total) }}
            </div>
          </template>
          <template v-slot:custom-foot>
            <b-tr class="">
              <b-td class="font-bold pt-1 pb-0">
                Total
              </b-td>
              <b-td class="text-black text-center font-bold pt-1 pb-0">
                {{ "X " + handleTotalProduct(detail.total_product) }}
              </b-td>
            </b-tr>
          </template>
        </BTable>
      </BOverlay>
    </div>
    <VueHtml2pdf
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1100"
      filename="packing-list"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
    >
      <section slot="pdf-content">
        <b-container class="pt-1">
          <div
            class="bg-[#F4F4F4]"
            style="border:3px solid #222222; border-radius:8px;"
          >
            <div class="head-parent">
              <div class="judul">
                Packing List
              </div>
              <div class="logo">
                <div class="via">
                  Pengiriman Via
                </div>
                <img
                  class="komship"
                  src="https://storage.googleapis.com/komerce/assets/svg/komship-logo-yellow.svg"
                >
              </div>
            </div>
            <BTable
              :items="products"
              :fields="fieldsPrint"
              empty-text="Tidak ada data yang ditampilkan."
              responsive
              show-empty
            >
              <template #cell(produk)="data">
                <div
                  class="d-flex items-start space-x-2"
                  style="min-width: 250px;"
                >
                  <img
                    :src="'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                    fluid
                    class="image-product"
                  >
                  <div class="">
                    <div class="text-black font-bold">
                      {{ data.item.product_name }}
                    </div>
                    <ul v-if="data.item.is_variant === 1">
                      <li
                        v-for="(item, index) in data.item.variant"
                        :key="index"
                      >
                        {{ item.variant_name }}
                      </li>
                    </ul>
                    <div
                      v-else
                      class=""
                    >
                      -
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(jumlah)="data">
                <div class="invisible">
                  {{ data.item.product_name }}
                </div>
                <ul
                  v-if="data.item.is_variant === 1"
                >
                  <li
                    v-for="(item, index) in data.item.variant"
                    :key="index"
                  >
                    {{ handleTotalProduct(item.total) }}
                  </li>
                </ul>
                <div
                  v-else
                  class=""
                >
                  {{ handleTotalProduct(data.item.total) }}
                </div>
              </template>
              <template v-slot:custom-foot>
                <b-tr class="bg-[#F4F4F4]">
                  <b-td
                    class="text-[16px] text-black align-top"
                    style="padding-top: 24px; padding-bottom: 3px;"
                  >
                    <div
                      class="font-bold"
                      style="margin-bottom: 12px;"
                    >
                      Waktu Pickup
                    </div>
                    <div class="">
                      {{ formatDate(detail.date) }}
                    </div>
                    <div class="text-[14px] text-[#828282]">
                      {{ detail.time }} WIB
                    </div>
                  </b-td>
                  <b-td
                    class="text-[16px] text-black"
                    style="padding-top: 24px; padding-bottom: 3px;"
                  >
                    <div
                      class="d-flex justify-between font-bold"
                      style="margin-bottom: 12px;"
                    >
                      <div class="">
                        Total Kuantitas :
                      </div>
                      <div class="">
                        {{ handleTotalProduct(detail.total_product) }} Pcs
                      </div>
                    </div>
                    <div
                      class="d-flex justify-between font-bold"
                      style="margin-bottom: 6px;"
                    >
                      <div class="">
                        Total Orderan*  :
                      </div>
                      <div class="">
                        {{ handleTotalProduct(detail.total_order) }} Paket
                      </div>
                    </div>
                    <div class="-mt-1">
                      *detail orderan cek di label pengiriman
                    </div>
                  </b-td>
                </b-tr>
              </template>
            </BTable>
          </div>
        </b-container>
      </section>
    </VueHtml2pdf>
  </BCard>
</template>

<script>
import moment from 'moment'
import VueHtml2pdf from 'vue-html2pdf'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: { VueHtml2pdf },
  data() {
    return {
      loading: false,
      detail: {},
      products: [],

      fields: [
        {
          key: 'produk',
          label: 'Produk',
          thClass: 'text-capitalize py-1',
          class: 'bg-white',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: '',
          },
          tdClass: '',
        },
        {
          key: 'jumlah',
          label: 'Jumlah',
          thClass: 'text-capitalize py-1 text-center',
          class: 'bg-white',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: '',
          },
          tdClass: 'text-black font-bold text-center',
        },
      ],
      fieldsPrint: [
        {
          key: 'produk',
          label: 'Produk',
          thClass: 'text-capitalize py-1',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '14px',
            color: '#222222',
            backgroundColor: '#F4F4F4',
          },
          tdClass: 'bg-white text-black',
        },
        {
          key: 'jumlah',
          label: 'Jumlah',
          thClass: 'text-capitalize py-1 text-center',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '14px',
            color: '#222222',
            backgroundColor: '#F4F4F4',
          },
          tdClass: 'bg-white text-black text-center',
        },
      ],
      dateTime: null,
    }
  },

  created() {
    this.fetchDetailPacking()
  },

  methods: {
    async fetchDetailPacking() {
      this.loading = true
      // ganti
      await this.$http_kompack.get(`/v1/kompack/outbound/${this.$route.params.id}/detail`)
        .then(response => {
          this.detail = response.data.data
          this.products = response.data.data.product
          this.dateTime = response.data.data.date
          this.loading = false
        }).catch(() => {
          this.loading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Gagal load data, silahkan coba lagi',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    goToDetail(id) {
      this.$router.push({
        path: `/packing/order/detail/${id}`,
        query: { time: this.dateTime },
      })
    },
    handleVehicle(value) {
      if (value === 'MOTOR') return 'https://storage.googleapis.com/komerce/assets/svg/motor-border.svg'
      if (value === 'MOBIL') return 'https://storage.googleapis.com/komerce/assets/svg/mobil-border.svg'
      if (value === 'TRUK') return 'https://storage.googleapis.com/komerce/assets/svg/truk-border.svg'
      return ''
    },
    formatDate(value) {
      return moment(value).format('DD MMMM YYYY')
    },
    downloadPackingList() {
      this.$refs.html2Pdf.generatePdf()
    },
    handleTotalProduct(value) {
      return `${value.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1.')}`
    },
  },
}
</script>

<style scoped>
.image-product{
  object-fit: cover;
  object-position: center center;
  width: 50px!important;
  height: 50px!important;
}

.head-parent {
  border-radius: 8px;
  display: flex;
  padding: 24px;
  align-items: center;
  background-color: white;
}
.judul {
  margin-top: -24px;
  width: 50%;
  color: #222222;
  font-size: 24px;
  font-weight: 600;
}
.logo {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}
.via {
  margin-top: -8px;
  font-weight: 600;
  font-size: 16px;
  color: #222222;
  align-self: flex-start;
}
.komship {
  width: 105px;
  height: 22px;
}
</style>
